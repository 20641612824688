import React from 'react';
import { Link } from 'react-router-dom';

export default function BottomNav() {
  const bottomMenu = [
    {
      name: 'Privacy policy',
      url: '/privacy-policy',
      isActive: true
    },
    {
      name: 'Customer support',
      url: '/customer-support',
      isActive: true
    },
    {
      name: 'User agreement',
      url: '/user-agreement',
      isActive: true
    },
    {
      name: 'GDPR',
      url: '/gdpr',
      isActive: true
    },
    {
      name: 'Blog',
      url: '/blog',
      isActive: true
    },
  ];

  return (
    <nav className='bottom-nav'>
      <ul className='bottom-menu'>
        {bottomMenu.map((menu, index) => (
          menu.isActive &&
            <li key={'bottom-menu' + index} >
              <Link to={menu.url}>{ menu.name}</Link>
            </li>
        ))}       
      </ul>
    </nav>
  )
}
