import React from 'react'
import goldline from '../img/goldline.png'

export default function LogoContainer() {
  return (
    <div className='logo-container'>
        <div className="text-box">
            <h2>
                <span>S</span>parkling
            </h2>
            <img src={goldline} alt="gold line" />
            <h2>
            <span>D</span>esire
            </h2>
        </div>
    </div>
  )
}
