import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div className='page privacy-policy'>
      <h1 className='temporarly-h1'>
        Privacy Policy
      </h1>
    </div>
  )
}
