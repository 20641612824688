// React and React Router Imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Style imports
import "../src/css/App.css";

// Image imports
import plants from './img/plants.png'

// Page component imports
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import Login from "./components/pages/Login";

// Navigation component imports
import BottomNav from "./components/navigation/BottomNav";
import MainNav from "./components/navigation/MainNav";
import PrivacyPolicy from "./components/bottom-pages/PrivacyPolicy";
import CustomerSupport from "./components/bottom-pages/CustomerSupport";
import UserAgreement from "./components/bottom-pages/UserAgreement";
import Gdpr from "./components/bottom-pages/Gdpr";
import Blog from "./components/bottom-pages/Blog";

function App() {
  return (
    <Router>
      <div className="App">
        <MainNav />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/login" element={<Login/>} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/gdpr" element={<Gdpr />} />
          <Route path="/blog" element={<Blog />} />

          <Route path="*" element={<h1>Page not found!</h1>} />

        </Routes>
        <BottomNav />
        <img className="plants" src={plants}></img>
      </div>
    </Router>
  );
}

export default App;
