import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import LogoContainer from '../LogoContainer'
import SloganContainer from '../SloganContainer'
import RegisterButton from '../RegisterButton'

export default function Home() {
  return (
    <div className='page home-page'>
        <div className='home-main-container'>
            <SloganContainer />
            <LogoContainer />
        </div>
        <div className='home-bottom-container'>
          <RegisterButton />
          <h1>
            <span>
              Explore, Connect,
            </span>
            <br></br>
            <span>
              Live your deepest desires
            </span>
          </h1>
          {
            false && <>
          <h2>
            Welcome to Sparkling Desire, where the boundaries of passion are redefined and every star in the sky pales in comparison to the sparks you'll feel.
          </h2>
          <h2>
            Here, singles and couples alike indulge in a world woven with the threads of desire, free from the constraints of orientation.
          </h2>

          <p>
            Embark on a journey where romance transcends, and intimate connections are not just made, but set aflame.
          </p>
          <p>
            Uncover the tapestry of temptation—your next chapter awaits. Register now and let the adventure begin.
          </p>
          </>}
        </div>
    </div>
  )
}
