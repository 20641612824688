import React from 'react'

export default function Login() {
  return (
    <div className='page login-page'>
        <h1 className='temporarly-h1'>
            Login
        </h1>
    </div>
  )
}
