import React from 'react'

export default function UserAgreement() {
  return (
    <div className='page page-user-agreement'>
        <h1 className='temporarly-h1'>
            User Agreement
        </h1>
    </div>
  )
}
