import React from 'react'

export default function Contact() {
  return (
    <div className='page contact-page'>
        <h1 className='temporarly-h1'>
            Contact
        </h1>
    </div>
  )
}
