import React from 'react'
import arrow from '../img/arrow1.png'

export default function SloganContainer() {
  return (
    <div className='slogan-container'>
        <img className='arrow' src={arrow} alt="arrow" />
        <h2>Live your desire</h2>
        <img className='arrow arrow-180' src={arrow} alt="arrow" />
    </div>
  )
}
