import React from 'react';
import { Link } from 'react-router-dom';

export default function MainNav() {
  const mainMenu = [
    {
      name: 'home',
      url: '/',
      isActive: true
    },
    {
      name: 'contact',
      url: '/contact',
      isActive: true
    },
    {
      name: 'login',
      url: '/login',
      isActive: true
    },
  ];

  return (
    <nav className='main-nav'>
      <div className='stripe'></div>
      <ul className='main-menu'>
        {mainMenu.map((menu, index) => (
          menu.isActive &&
            <li key={'main-menu' + index} >
              <Link to={menu.url}>{ menu.name}</Link>
            </li>
        ))}       
      </ul>
    </nav>
  )
}
