import React from 'react'

export default function Blog() {
  return (
    <div className='page page-blog'>
        <h1 className='temporarly-h1'>
            Blog
        </h1>
    </div>
  )
}
