import React from 'react'

export default function CustomerSupport() {
  return (
    <div className='page page-customer-support'>
        <h1 className='temporarly-h1'>
            Customer Support
        </h1>
    </div>
  )
}
