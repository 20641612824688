import React from 'react'

export default function Gdpr() {
  return (
    <div className='page page-gdpr'>
        <h1 className='temporarly-h1'>
            GDPR
        </h1>
    </div>
  )
}
